import React, { Fragment, useState } from 'react'
import { useSelector } from 'react-redux';
import FaqAccordion from './FaqAccordion'
import Analytics from '../../../common_components/Common/Analytics';
function FaqSection({ type }) {
  const { faqList } = useSelector(
    (state) => state.home
  );
  const [active, setActive] = useState(null);
   const handleToggle = (index, faq) => {
      if (active === index) {
          setActive(null);
      } else {
          setActive(index);
      }      
    const Analyticsprops = {
        GA: true,
        title: 'Homepage FAQ',
        category: 'Faq section',
        action: 'faq accordian',
        label: `${faq.que} Question clicked`,
      }
      Analytics(Analyticsprops)
  }
     return(
    <Fragment>
             <div className={` ${type === 1 ? 'mb-5' : ''}`}>
                <div className="row common-parent" >
                    <div className="col-md-12 mt-2">
                            <div className='mb-2 fw-500 fs-18' ><h2 className='title'>FAQ'S On EV</h2></div>
                        <div className="card">
                            <div className="card-body px-0">
                          
                                {faqList?.docs?.map((faq, index ) => {
                                     return (
                                            <FaqAccordion key={index} active={active} handleToggle={(e)=>handleToggle(index, faq)} faq={faq} index={index} type={type} />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </Fragment>
   )  
}

export default FaqSection